











































































































































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Row, Image, Col, Button, Popup, NavBar, Toast } from "vant";
@Component({
  components: {
    Mixin,
    [Row.name]: Row,
    [Col.name]: Col,
    [Image.name]: Image,
    [Button.name]: Button,
    [Popup.name]: Popup,
    [NavBar.name]: NavBar,
  },
})
export default class ClubDetail extends Mixins(Mixin) {
  public activityInfo: cms.ApplyActivityResponse = {};
  public activityId = "";
  public showWriteEnter = false;
  actDesc = "";
  theaterFocusTxt = false;

  /**
   * 外部跳转 H5 异步获取 token、channel 的异步请求完成后执行
   */
  @Watch("ExternalAccessModule.isExternalAccessReady", {
    immediate: true,
    deep: true,
  })
  readyStateChange(isExternalAccessReady: boolean): void {
    if (isExternalAccessReady) {
      this.updateData();
    }
  }
  // 现实报名金额
  applyTypeArr(info: any): string {
    //  1-免费参加 2-购票兑换 3-付费活动 4-积分兑换 ,
    if (info.applyType == "1") {
      return "免费";
    } else if (info.applyType == "2") {
      return "购票兑换";
    } else if (info.applyType == "3") {
      return "¥" + info.applyMoney + "元";
    } else {
      return info.applyIntegral + "积分";
    }
  }
  get BtnName(): string {
    let title = "";
    // 已报名
    if (this.activityInfo.crtMemberIsApplay === "1") {
      if (this.activityInfo.crtMemberApplyStatus === "0") {
        title = "待审核";
      } else if (this.activityInfo.crtMemberApplyStatus === "1") {
        title = "已入选";
      } else if (this.activityInfo.crtMemberApplyStatus === "2") {
        title = "未入选";
      } else if (this.activityInfo.crtMemberApplyStatus === "3") {
        title = this.getButtonTxt(this.activityInfo.applyStatus!).text;
      }
    } else {
      title = this.getButtonTxt(this.activityInfo.applyStatus!).text;
    }
    return title;
  }

  updateData(): void {
    window.scrollTo(0, 0);
    let activityId = this.$route.query.id;
    this.activityId = activityId ? String(activityId) : "";
    if (this.activityId) {
      this.getActDetail();
    }
  }
  getActDetail(): void {
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    this.$api.cmsApi.applyActivity.findApplyActivityByActivityId(
      this.activityId,
      (data) => {
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.share;
        }
        Toast.clear();
        this.theaterFocusTxt = data.data.theaterIsFocus!;
        this.activityInfo = data.data;
        window.document.title =
          this.activityInfo.activityName || "爱演出 爱生活-保利票务";
        this.actDesc = this.activityInfo.activityDescription!.replace(
          /<img/g,
          "<img style='max-width:100%;height:auto;'"
        );
      }
    );
  }
  getButtonTxt(status: string): {
    text: string;
  } {
    let obj = {
      text: "立即报名",
    };
    if (status === "ACTIVITY_APPLY_STATUS_COMING") {
      obj.text = "即将开始";
    } else if (status === "ACTIVITY_APPLY_STATUS_ONWAY") {
      obj.text = "立即报名";
    } else {
      obj.text = "报名结束";
    }
    return obj;
  }
  ApplyAct(status: string): void {
    if (status === "ACTIVITY_APPLY_STATUS_ONWAY") {
      this.$router.push(`/apply-enter-activity?activityId=${this.activityId}`);
    }
  }
  //改变关注状态
  changeFocus(): void {
    this.$api.memberApi.attentionAndCollection.updateTheaterShopFollow(
      this.activityInfo.theaterId!,
      ({ data }) => {
        this.theaterFocusTxt = !data;
        this.getActDetail();
      }
    );
  }
  formDate(date: string): string {
    const dt = new Date(Number(date));
    const y = dt.getFullYear();
    const m = (dt.getMonth() + 1 + "").padStart(2, "0");
    const d = (dt.getDate() + "").padStart(2, "0");
    const hh = (dt.getHours() + "").padStart(2, "0");
    const mm = (dt.getMinutes() + "").padStart(2, "0");

    return `${y}.${m}.${d} ${this.getWeek(dt)}  ${hh}:${mm}`;
  }
  getWeek(date: Date): string {
    let week;
    if (date.getDay() == 0) week = "星期日";
    if (date.getDay() == 1) week = "星期一";
    if (date.getDay() == 2) week = "星期二";
    if (date.getDay() == 3) week = "星期三";
    if (date.getDay() == 4) week = "星期四";
    if (date.getDay() == 5) week = "星期五";
    if (date.getDay() == 6) week = "星期六";
    return String(week);
  }
  share(): void {
    let url = `${location.origin}${location.pathname}#/enter-activity-detail?id=${this.activityId}`;
    if (
      this.ChannelModule.channel === "plat_android" ||
      this.ChannelModule.channel === "plat_ios"
    ) {
      this.jsBridge(
        "share",
        `title%&@/${
          this.activityInfo.activityName
        }%&@,desc%&@/${this.formatrichtext(
          this.activityInfo.activityDescription!
        )}%&@,picUrl%&@/${this.activityInfo.activityImageUrl}%&@,url%&@/${url}`
      );
    }
  }
  formatrichtext(richtext: string): string {
    let content = richtext.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi, "");
    return content;
  }
}
